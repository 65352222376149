// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-full-check-js": () => import("../src/pages/full-check.js" /* webpackChunkName: "component---src-pages-full-check-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tech-check-js": () => import("../src/pages/tech-check.js" /* webpackChunkName: "component---src-pages-tech-check-js" */),
  "component---src-pages-value-check-js": () => import("../src/pages/value-check.js" /* webpackChunkName: "component---src-pages-value-check-js" */)
}

